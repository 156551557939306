<template>
    <Transition name="slide-from-right-side">
        <section
            v-if="isNotificationsViewOpen"
            v-click-outside="onClose"
            data-cy="notifications-view"
            class="notifications-view shadow-lg bg-white"
        >
            <header class="px-4 py-2 d-flex align-items-center justify-content-between bg-primary text-white">
                <h2 class="m-0 font-size-lg">{{ $t(`What is new`) }}</h2>

                <button
                    type="button"
                    class="btn btn-link text-white"
                    @click="onClose"
                >
                    <i class="fd-close font-size-lg"></i>
                </button>
            </header>

            <section class="px-4 pb-2 content-view">
                <article
                    v-for="notification in sortedNotifications"
                    :key="notification.id"
                    class="pb-3 pt-3 border-bottom"
                >
                    <div class="d-flex justify-content-between">
                        <div class="col-2 p-0">
                            <figure class="notification-img-box">
                                <img
                                    class="rounded-circle"
                                    :src="notification.preview_file_name"
                                    alt
                                />
                            </figure>
                        </div>

                        <div class="col-10 pr-0">
                            <h3 class="text-muted mb-2">{{ $filters.localeOrSomething(notification.title) }}</h3>
                            <div
                                class="text-primary mx-y"
                                v-html="getIntro(notification.intro)"
                            ></div>
                            <time
                                class="text-muted mt-2 d-block"
                                :datetime="notification.update_at"
                            >
                                {{ notification.updated_at }}
                            </time>
                        </div>
                    </div>
                </article>
            </section>
        </section>
    </Transition>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapGetters } from 'vuex';

import { CLOSE_NOTIFICATIONS_VIEW } from '@store/modules/notifications/constants';

export default {
    computed: {
        ...mapGetters(['isNotificationsViewOpen', 'notifications', 'isNotificationDetailsShown']),
        sortedNotifications() {
            let notifications = cloneDeep(this.notifications);
            notifications.sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1));
            return notifications;
        },
    },

    methods: {
        getIntro(value) {
            return this.$options.filters.localeOrSomething(value);
        },
        onClose() {
            if (!this.isNotificationDetailsShown) {
                this.$store.commit(CLOSE_NOTIFICATIONS_VIEW);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.notifications-view {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    width: 450px;
    height: 100vh;

    & header {
        max-height: 62px;
        border-top: solid 4px var(--green);
    }

    & .content-view {
        height: calc(100% - 62px);
        overflow: auto;

        & .notification-img-box {
            position: relative;
            width: 60px;
            height: 60px;
            overflow: hidden;
            background-image: url('/public/img/placeholder.png');
            background-position: center;
            background-size: contain;
            border-radius: 50%;

            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                min-width: 100%;
                min-height: 100%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
</style>

<style lang="scss">
.notifications-view {
    z-index: 999999 !important;

    & .content-view {
        p {
            margin: 0;
        }
    }
}
</style>
