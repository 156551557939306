<template>
    <div class="table-container">
        <div class="sort-filter-buttons-wrapper">
            <div class="sort-button">
                <button
                    class="btn btn-icon"
                    @click="sortBy(internalSortKey)"
                >
                    <i :class="getSortIconClass(internalSortKey)"></i>
                </button>
                <span
                    v-if="internalSortKeyLabel"
                    class="badge-pill font-size-xxs bg-success-light"
                >
                    {{ internalSortKeyLabel }}
                </span>
                <span
                    v-else
                    class="badge-pill font-size-xxs bg-gray-200"
                    @click="toggleShowSortOptions"
                >
                    {{ $t('Sort by') }}
                </span>
            </div>
            <slot name="filter-slot"></slot>
        </div>

        <div
            v-if="showSortOptions"
            class="my-2 d-flex flex-wrap"
        >
            <span
                v-for="field in fields"
                :id="field.key"
                :key="field.key"
                class="badge-pill font-size-xxs bg-gray-200 mb-1 mr-1"
                @click="sortBy(field.key)"
            >
                {{ $t(field.label) }}
            </span>
        </div>

        <div class="table-wrapper">
            <table
                class="table layout-fixed"
                :class="{ 'scrollable-table': scrollableTable, tableClass }"
            >
                <thead>
                    <tr class="dark-border-bottom">
                        <th
                            v-for="(field, index) in fields"
                            :key="field.key"
                            class="font-size-xxs"
                            :class="(index === fields.length - 1 && lastColSticky ? 'sticky-col ' : '') + field.thClass"
                            @click="sortBy(field.key)"
                        >
                            <span class="d-flex align-items-center">
                                <slot :name="`head(${field.key})`">
                                    {{ field.label }}
                                </slot>
                                <span
                                    v-if="field.sortable"
                                    class="sort-icon"
                                >
                                    <i :class="getSortIconClass(field.key)"></i>
                                </span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="item in sortedAndPaginatedItems"
                        :id="item.id"
                        :key="item.id"
                        :class="rowClass(item)"
                    >
                        <td
                            v-for="(field, index) in fields"
                            :key="field.key"
                            :ref="setItemRef"
                            :data-field="field.key"
                            :data-item-id="item.id"
                            :data-label="field.label"
                            class="font-size-xs"
                            :class="(index === fields.length - 1 && lastColSticky ? 'sticky-col ' : '') + field.tdClass"
                            @click="
                                !['actions', 'files'].includes(field.key) &&
                                    field.type !== 'attach_file' &&
                                    rowClicked(item)
                            "
                        >
                            <slot
                                :name="`cell(${field.key})`"
                                :item="item"
                                :value="item[field.key]"
                            >
                                {{ getRenderedText(item.id, field.key) || item[field.key] }}
                            </slot>
                        </td>
                    </tr>
                    <template v-if="items.length === 0">
                        <tr>
                            <td
                                :colspan="fields.length"
                                class="text-center font-size-xs"
                            >
                                <slot name="empty">{{ $t('There are no records to show') }}</slot>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DataTable',

    props: {
        items: {
            type: Array,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        pageSize: {
            type: Number,
            default: 5,
        },
        sortCompare: {
            type: Function,
            default: null,
        },
        sortKey: {
            type: String,
            default: 'id',
        },
        tableClass: {
            type: String,
            default: 'table',
        },
        scrollableTable: {
            type: Boolean,
            default: false,
        },
        lastColSticky: {
            type: Boolean,
            default: false,
        },
        tbodyTrClass: {
            type: [Function, String],
            default: undefined,
        },
    },

    emits: ['row-clicked'],

    data() {
        return {
            curPage: 1,
            sortAsc: true,
            itemRefs: {},
            internalSortKey: this.sortKey,
            showSortOptions: false,
        };
    },

    computed: {
        sortedAndPaginatedItems() {
            let sortedItems = [...this.items];
            if (this.internalSortKey) {
                sortedItems.sort((a, b) => {
                    const valA = this.getRenderedText(a.id, this.internalSortKey);
                    const valB = this.getRenderedText(b.id, this.internalSortKey);
                    let result = this.sortCompare
                        ? this.sortCompare(valA, valB, this.internalSortKey, this.sortAsc)
                        : this.defaultSort(valA, valB);
                    return this.sortAsc ? result : -result;
                });
            }
            return sortedItems;
        },
        internalSortKeyLabel() {
            return this.fields.find((field) => field.key === this.internalSortKey)?.label;
        },
    },

    watch: {
        sortKey(newValue) {
            this.internalSortKey = newValue;
        },
    },

    methods: {
        rowClass(item) {
            if (typeof this.tbodyTrClass === 'function') {
                return this.tbodyTrClass(item, 'row');
            } else if (typeof this.tbodyTrClass === 'string') {
                return this.tbodyTrClass;
            } else {
                return '';
            }
        },
        rowClicked(item) {
            this.$emit('row-clicked', item);
        },
        sortBy(key) {
            if (this.internalSortKey === key) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.internalSortKey = key;
                this.sortAsc = true;
            }
        },
        getSortIconClass(key) {
            if (!this.internalSortKey || this.internalSortKey !== key) {
                return 'fd-arrows';
            }
            return this.sortAsc ? 'fd-arrow-up' : 'fd-arrow-down';
        },
        defaultSort(valA, valB) {
            if (!isNaN(valA) && !isNaN(valB)) {
                return valA - valB;
            }
            if (valA < valB) {
                return -1;
            }
            if (valA > valB) {
                return 1;
            }
            return 0;
        },
        extractValue(item, key) {
            const keys = key.split('.');
            let value = item;
            for (let k of keys) {
                value = value ? value[k] : '';
            }
            return value;
        },
        setItemRef(el) {
            if (el) {
                const field = el.dataset.field;
                const itemId = el.dataset.itemId;
                if (!this.itemRefs[itemId]) {
                    this.itemRefs[itemId] = {};
                }
                this.itemRefs[itemId][field] = el;
            }
        },
        getRenderedText(itemId, field) {
            const el = this.itemRefs[itemId] ? this.itemRefs[itemId][field] : null;
            return el ? el.innerText?.trim() : ' ';
        },
        toggleShowSortOptions() {
            this.showSortOptions = !this.showSortOptions;
        },
    },
};
</script>

<style scoped lang="scss">
.table-container {
    width: 100%;
    overflow: auto;
}
.table-wrapper {
    @media screen and (min-width: 992px) {
        width: calc(80vw - 300px) !important;
    }
    @media screen and (min-width: 1240px) {
        width: 100% !important;
        max-width: calc(84vw - 300px) !important;
    }
}

// scrolling, stickies, alignment
.table-wrapper {
    width: 100%;
    display: block;
    position: relative;
}

.table.scrollable-table {
    width: 100%;

    th,
    td {
        padding: 10px;
        text-align: left;
    }

    .sticky-col {
        min-width: 1rem;
        position: sticky;
        right: 0;
        background: white;
        z-index: 1;
    }

    thead th:first-child,
    thead th:last-child {
        z-index: 3;
    }
}

// styles
.table {
    border-collapse: collapse;

    th,
    td {
        border: none;
        padding: 1rem 0.5rem;
    }

    th {
        font-weight: normal;
        text-align: left;
        color: $gray-600 !important;
        letter-spacing: 0.05rem;
    }

    tr {
        border-bottom: 1px solid $gray-300;
    }

    > tbody tr {
        &:hover {
            cursor: pointer;
            background-color: $gray-200;

            // the badges
            .bg-gray-200 {
                background-color: $gray-300 !important;
            }
        }
    }

    .dark-border-bottom {
        border-bottom: 1px solid $gray-600;

        &:hover {
            background-color: white !important;
        }
    }
    @media (max-width: 768px) {
        display: block;

        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        th {
            font-size: 0.75rem;
            margin-bottom: 0.5rem;
        }

        tr {
            border: 1px solid $gray-400;
            margin: 0 0 1rem 0;
            border-radius: 0.25rem;
            padding: 0.5rem;
        }

        td {
            padding: 0.5rem;
            position: relative;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 0.25rem;
            margin-top: 0.25rem;
            border-bottom: 1px solid $gray-300;

            &:before {
                content: attr(data-label);
                color: $gray-600;
                margin-bottom: 0.25rem;
            }
        }
    }
}

.sort-filter-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 768px) {
        justify-content: space-between;
    }

    margin-bottom: 1rem;
}

.sort-button {
    display: none;

    @media screen and (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

.sort-icon {
    margin-left: 0.5rem;
    color: $green;
}
</style>
