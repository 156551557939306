<template>
    <div class="row">
        <div class="offset-md-5 col-md-7 text-center">
            <h2>{{ $t('changing_name') }}</h2>
        </div>
        <BaseForm
            class="col-12"
            @submit="changeName"
        >
            <div class="form-group row">
                <label
                    :for="'firstName'"
                    class="col-md-5 col-form-label text-md-right text-sm-left"
                >
                    {{ $t('first_name') }}</label
                >
                <div class="col-md-7">
                    <BaseInput
                        id="firstName"
                        v-model="firstName"
                        rules="required"
                    >
                    </BaseInput>
                </div>
            </div>

            <div class="form-group row">
                <label
                    :for="'lastName'"
                    class="col-md-5 col-form-label text-md-right text-sm-left"
                >
                    {{ $t('last_name') }}</label
                >
                <div class="col-md-7">
                    <BaseInput
                        id="lastName"
                        v-model.trim="lastName"
                        rules="required"
                    >
                    </BaseInput>
                </div>
            </div>

            <div class="form-group row">
                <div class="offset-md-5 col-md-7">
                    <button
                        type="submit"
                        class="btn btn-primary btn-block"
                    >
                        {{ $t('save') }}
                    </button>
                </div>
            </div>
        </BaseForm>
    </div>
</template>

<script>
import { mapActions } from 'pinia';
import { mapGetters } from 'vuex';

import { CHANGE_NAME } from '@store/modules/profile/constants';
import { useLoaderStore } from '@store/pinia-utils/loader';

export default {
    data() {
        return {
            firstName: '',
            lastName: '',
        };
    },

    computed: {
        ...mapGetters(['userProfile']),
    },

    watch: {
        userProfile: function (profile) {
            this.setName(profile);
        },
    },

    created() {
        this.setName(this.userProfile);
    },

    methods: {
        ...mapActions(useLoaderStore, ['setLoading']),
        async changeName({ validation }) {
            const valid = await validation.validate();
            if (valid) {
                this.setLoading(true);
                const data = {
                    first_name: this.firstName,
                    last_name: this.lastName,
                };
                this.$store
                    .dispatch(CHANGE_NAME, data)
                    .then(() => {
                        this.$toastr.s(this.$t('name_change_success'), this.$t('success') + '!');
                    })
                    .finally(() => {
                        this.setLoading(false);
                    });
            }
        },
        setName(profile) {
            this.firstName = profile.firstname;
            this.lastName = profile.lastname;
        },
    },
};
</script>
