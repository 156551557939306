<template>
    <ValidationProvider
        v-slot="v"
        :rules="rules"
        tag="div"
        class="custom-control custom-checkbox"
        :class="{ disabled: disabled, big: big, rounded: rounded, medium: medium }"
    >
        <input
            :id="id"
            v-model="valModel"
            :disabled="disabled"
            class="custom-control-input"
            type="checkbox"
            :value="val"
            :data-cy="`${dataCy}-checkbox`"
        />
        <label
            v-if="!$slots.label && !label"
            :for="id"
            class="custom-control-label"
            :class="{
                'has-error': (validation && validation.$error) || v.errors.length,
                'no-label': !$slots.label && !label,
            }"
        ></label>
        <label
            v-else
            :for="id"
            class="custom-control-label"
            :class="{
                'has-error': (validation && validation.$error) || v.errors.length,
                'no-label': !$slots.label && !label,
            }"
            :data-cy="dataCy"
            ><slot name="label">{{ label }}</slot></label
        >
        <slot></slot>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'BaseCheckbox',

    components: {
        ValidationProvider,
    },

    props: {
        id: {
            type: String,
            required: true,
        },
        rules: {
            type: [String, Object],
            default: '',
        },
        value: {
            type: [Object, Boolean, Array, Number, String],
            default: () => [],
        },
        val: {
            type: [Boolean, Number, String, Object],
            default: true,
        },
        validation: {
            type: Object,
            nullable: true,
            default: null,
        },
        label: {
            type: String,
            default: '',
        },
        dataCy: {
            type: String,
            default: '',
        },
        disabled: Boolean,
        invalid: Boolean,
        rounded: Boolean,
        big: Boolean,
        medium: Boolean,
    },

    emits: ['input'],

    computed: {
        valModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', Array.isArray(value) ? value[0] : value);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-control-label {
    padding-top: 1px;
    font-size: 0.875rem;
    &.has-error {
        &::before {
            background: lighten($danger, 50%);
            border-color: var(--danger);
        }
    }

    &.no-label {
        display: inline-block;

        &:before,
        &:after {
            transform: none;
        }
    }

    &:before {
        background-color: $gray-300;
        border-color: $gray-300;
    }

    &:before,
    &:after {
        top: 50%;
        transform: translateY(-50%);
    }
    &:hover {
        cursor: pointer;
    }
}

.big {
    .custom-control-label {
        padding-left: 1rem;

        &:before,
        &:after {
            width: 2rem;
            height: 2rem;
        }
    }
}

.medium {
    .custom-control-label {
        padding-left: 0.5rem;

        &:before,
        &:after {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}

.rounded {
    .custom-control-label {
        &:before,
        &:after {
            border-radius: 50%;
        }
        &:after {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
        }
    }
}
</style>
