<template>
    <ValidationProvider
        v-if="isEditable"
        v-slot="v"
        :name="name"
        :rules="rules"
        mode="eager"
        slim
    >
        <div
            class="base-datepicker grid-template-with-label max-width position-relative"
            :class="{ 'has-feedback has-error': v.errors.length }"
        >
            <fd-date-picker
                ref="date-picker"
                :name="name"
                :date-picker-id="id"
                :value="dateVal"
                :placeholder="placeholder || format"
                :inert="disabled"
                @input="onInput"
            >
                <label
                    v-if="isLabelSlot"
                    slot="picker-label"
                    class="p-0"
                    for="date-input"
                >
                    <slot name="label"></slot>
                </label>

                <label
                    slot="icon-label"
                    class="d-flex align-items-end justify-content-end cursor-text negative-margin-calendar-label"
                    :for="id"
                    @click="onLabelClick"
                >
                    <i
                        v-if="enableClear && value"
                        :class="{ 'calendar-icon-with-text-label': isLabelSlot }"
                        class="fd-close text-gray-500"
                    />
                    <i
                        v-else
                        class="calendar-icon fd-calendar d-none-for-print text-gray-500"
                        :class="{ 'calendar-icon-with-text-label': isLabelSlot }"
                    ></i>
                </label>

                <!--  Can only access these from this scope              -->
                <i
                    id="prev-month"
                    slot="prev-button-icon"
                    class="fd-chevron-left"
                ></i>
                <i
                    id="next-month"
                    slot="next-button-icon"
                    class="fd-chevron-right"
                ></i>
            </fd-date-picker>

            <span
                v-if="v.errors.length"
                class="invalid-feedback d-block"
                >{{ v.errors[0] }}</span
            >
        </div>
    </ValidationProvider>
    <span v-else>
        {{ dateVal || '-' }}
    </span>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

import { DateValue } from '@common/types';

export default {
    name: 'BaseDatePicker',

    components: {
        ValidationProvider,
    },

    props: {
        id: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        rules: {
            type: String,
            default: '',
        },
        value: {
            type: [DateValue, null],
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        disabled: Boolean,
        hidePlaceHolder: Boolean,
        isEditable: {
            type: Boolean,
            default: true,
        },
        enableClear: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['input'],

    computed: {
        ...mapGetters({
            entriesDiapasonState: 'activityLog/entriesDiapasonState',
        }),
        format() {
            return DateValue.getCompanyDateFormat();
        },
        isLabelSlot() {
            return !!this.$slots.label;
        },
        dateVal: {
            get() {
                if (!this.value) {
                    return null;
                }

                return new DateValue(this.value).dateFormat;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },

    methods: {
        onLabelClick() {
            if (this.enableClear && this.value) {
                this.onInput(null);
            } else {
                try {
                    this.$refs['date-picker'].shadowRoot.getElementById('date').focus();
                } catch (error) {
                    console.error(error);
                }
            }
        },
        onInput(value) {
            this.dateVal = value?.detail;
        },
    },
};
</script>

<style>
.negative-margin-calendar-label {
    right: 0.75rem;
    position: absolute;
    padding-top: 0.25rem;
}
</style>
