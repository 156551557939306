<template>
    <SkeletonTable
        v-if="loader"
        :rows="loaderOptions.rows"
        :columns="loaderOptions.columns"
    />
    <DataTable
        v-else
        :fields="fields"
        :items="items"
        :sort-key="primaryKey"
        :page-size="25"
        :scrollable-table="tableIsScrollable"
        :last-col-sticky="lastColSticky"
        :tbody-tr-class="tbodyTrClass"
        @row-clicked="$emit('row-clicked', $event)"
    >
        <template #filter-slot>
            <slot name="filter-slot"></slot>
        </template>

        <slot
            v-for="(_, name) in $slots"
            :slot="name"
            :name="name"
        />
        <template
            v-for="(_, name) in $scopedSlots"
            :slot="name"
            slot-scope="slotData"
        >
            <slot
                :name="name"
                v-bind="slotData"
            />
        </template>
    </DataTable>
</template>

<script>
import DataTable from './DataTable.vue';
import SkeletonTable from './SkeletonTable.vue';

export default {
    name: 'BaseTable',

    components: {
        SkeletonTable,
        DataTable,
    },

    props: {
        tableInfoLinkText: {
            type: String,
            default: '',
        },
        tableInfoText: {
            type: String,
            default: '',
        },
        loader: Boolean,
        loaderOptions: {
            type: Object,
            default: () => ({
                rows: 3,
                columns: 4,
            }),
        },
        primaryKey: {
            type: String,
            default: 'id',
        },
        tableClass: {
            type: String,
            default: '',
        },
        singleRow: Boolean,
        alignTop: Boolean,
        stacked: {
            type: String,
            default: 'md',
        },
        cursorPointer: Boolean,
        stickyHeader: {
            type: [Boolean, String],
            default: false,
        },
        noBorderCollapse: {
            type: [Boolean],
            default: true,
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
        lastColSticky: {
            type: Boolean,
            default: false,
        },
        tbodyTrClass: {
            type: [Function, String],
            default: undefined,
        },
    },

    emits: ['table-info-clicked', 'row-clicked'],

    data() {
        return {
            scrollX: 0,
        };
    },

    computed: {
        fields() {
            return this.$attrs.fields;
        },
        items() {
            return this.$attrs.items;
        },
        infoRowSize() {
            return this.$attrs?.fields?.length;
        },
        tableIsScrollable() {
            return this.scrollable; // Todo should also maybe depend on param or route or something?
        },
    },

    mounted() {
        if (this.tableInfoText) {
            this.$refs['base-table'].$el.addEventListener('scroll', this.updateScroll);
        }
    },

    beforeDestroy() {
        if (this.tableInfoText) {
            this.$refs['base-table'].$el.removeEventListener('scroll', this.updateScroll);
        }
    },

    methods: {
        updateScroll(event) {
            this.scrollX = event.target.scrollLeft;
        },
    },
};
</script>
