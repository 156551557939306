const styles = `
<style>
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');

*, *:before, *:after {
  font-family: Rubik, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

 #date, #date::placeholder, #date:before, #date:after {
  font-family: Rubik, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
    font-size: 0.875rem;
}

#date::placeholder {
    color: var(--gray-500);
}


.max-width {
  display: block;
  /*width: 10rem;*/
}

.calendar-icon {
  position: absolute;
  bottom: -0.25rem;
  right: 0;
  color: var(--gray-200);
  transform: translate(-50%, -50%);
}

.calendar-icon-with-text-label {
  position: absolute;
  bottom: 0.08rem;
  right: 0;
  color: var(--gray-500);
  transform: translate(-50%, -50%);
}

.calendar-icon-with-text-label.fd-close {
  right: 0.25rem;
}

.base-datepicker {
  height: 100%;
}

.date-input {
  height: calc(1.5em + 0.75rem + 4px);
  background-color: var(--gray-200);
  border-radius: 0.3rem;
  border: 0;
  transition: border 0.3s ease-in-out;


 }


#date, #date:hover, #date:focus, #date:focus-visible, #date:active {
    padding-left: 0.75rem;
    background-color: transparent !important;
    height: 100%;
    width: 100%;
}

#date {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

    border: 2px solid transparent;
}
#date:focus, #date:focus-visible {
  /*outline-color: var(--light-green) !important;*/
  outline: 0;
  box-shadow: var(--input-focus-shadow);
  border-radius: 0.25rem;
  border-color:   var(--input-focus-border-color);
}

.date-input input {
    font-size: 1rem !important;
    padding: 0.375rem 0.75rem !important;
    line-height: 1.5;
}

.date-input:hover {
  background-color: var(--gray-300);
}

.base-datepicker label {
  font-size: 0.875rem;

  font-weight: normal;
}

.base-datepicker {
  background-color: white;
}

.date-input {
    display: flex;
    align-items: center;
}

.date-input:focus, .date-input:focus-visible {

  box-shadow: var(--input-focus-shadow);
}

#grid {
  z-index: 10000;
  position: absolute;
  border-radius: 0.5rem;
  box-shadow: 0 2px 20px rgba(134, 134, 134, 0.3);
  width: 16rem;
  inset: 50px auto auto 0px;

  .row {
    display: flex; justify-content: space-around;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }

}

.day {
    width: 2rem;
    height: 2rem;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 50%;
       font-family: Rubik, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;

    &:hover {
      background-color: var(--gray-200);
    }
    &.selected {
      border: var(--gray-700) 1px solid;
      background-color: white;
    }
}
.day-name {
  color: var(--gray-600);
  font-size: 0.75rem;
  text-align: center;
}
.other-month {
color: var(--gray-500);
}
.controls {
  display: flex; align-items: center; justify-content: space-between;
  padding: 0.5rem;

  .month {
  font-size: 0.875rem;
  }
}

#grid, .days-grid {
  background-color: white !important;
  border-radius: 0.5rem;
}

#grid {
  padding: 0 0.5rem 0.5rem 0.5rem;

}
.buttons {
  display: flex; align-items: center; justify-content: space-between;
  .prev-month, .next-month {
      border: 1px solid transparent;
      border-radius: 50%;
      background-color: white;
      width: 2.25rem;
      height: 2.25rem;
      text-align: center;
        display: flex; align-items: center; justify-content: center;


    &:hover {
      background-color: var(--gray-200);
      color: var(--gray-700);
    }

  }
}

label {
  padding: 0;
  margin: 0;
}
</style>
`;

// @ts-ignore
const stylesheet = new CSSStyleSheet();

stylesheet.replaceSync(styles);

export default stylesheet;
